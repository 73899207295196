<template>
  <header>
    <div
        class="logo"
        :style="{
          background: 'url(/img/logo/' + selectLang + '.svg) no-repeat center',
          height: selectLang === 'th' && wightWin <= 768 ? '13vw' : ''
        }">
    </div>
    <h1
        class="title"
        :style="{
          fontSize: selectLang === 'th' ? '40px' : ''
        }"
    >{{ data[selectLang].title }}</h1>
    <div class="subTitle">{{ data[selectLang].subTitle }}</div>
  </header>
  <main></main>
  <footer>
    <div
        class="text"
        :style="{width: data[selectLang].textWight}"
    >{{ data[selectLang].text }}</div>
    <a :href="data[selectLang].src">
      <div class="btn">{{ data[selectLang].textBtn }}</div>
    </a>
    <div class="lang">
      <div
          v-for="(ln, i) in lang"
          :key="i"
          @click="selectLang = ln"
          :class="selectLang === ln ? 'active' : ''"
      >{{ ln }}
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      lang: ['en', 'hi', 'vi', 'id', 'ms', 'th', 'tl', 'tr'],
      data: {
        'en': {
          title: 'is waiting for you!',
          subTitle: 'Slots, Andar Bahar, Blackjack, Roulette and lots more!',
          textWight: 'auto',
          text: 'Play the best casino games!',
          textBtn: 'win now',
          src: 'https://cropped.xyz/x7545'
        },
        'hi': {
          title: 'sedang menunggu untuk anda!',
          subTitle: 'Slot, Andar Bahar, Blackjack, Rolet dan banyak lagi!',
          textWight: window.outerWidth <= 426 ? '230px' : '400px',
          text: 'Main dan menang dengan kasino yang terbaik!',
          textBtn: 'Pergi ke laman web',
          src: 'https://cropped.xyz/nxsyl'
        },
        'vi': {
          title: 'đang đợi bạn!',
          subTitle: 'Quay số, Andar Bahar, Blackjack, Roulette và còn nhiều nữa!',
          textWight: window.outerWidth <= 426 ? '200px' : '300px',
          text: 'Chơi và thắng cùng casino tốt nhất!',
          textBtn: 'Đến trang web',
          src: 'https://cropped.xyz/fb06i'
        },
        'id': {
          title: 'sizi bekliyor!',
          subTitle: 'Slotlar, Andar Bahar, Blackjack, Rulet ve çok daha fazlası!',
          textWight: window.outerWidth <= 426 ? '165px' : 'auto',
          text: 'En iyi casinoda oynayın ve kazanın!',
          textBtn: 'Web sitesine git',
          src: 'https://cropped.xyz/vjcrw'
        },
        'ms': {
          title: 'sedang menunggu untuk anda!',
          subTitle: 'Slot, Andar Bahar, Blackjack, Rolet dan banyak lagi!',
          textWight: window.outerWidth <= 426 ? '230px' : '400px',
          text: 'Main dan menang dengan kasino yang terbaik!',
          textBtn: 'Pergi ke laman web',
          src: 'https://cropped.xyz/itv3a'
        },
        'th': {
          title: 'กำลังรอคุณอยู่!',
          subTitle: 'สล็อต, Andar Bahar, แบล็กแจ็ก, รูเล็ตต์และอื่น ๆ อีกเพียบ!',
          textWight: 'auto',
          text: 'เล่นและชนะไปกับคาสิโนที่ดีที่สุด!',
          textBtn: 'ไปยังเว็บไซต์',
          src: 'https://cropped.xyz/pqhdq'
        },
        'tl': {
          title: 'Naghihintay sa iyo ang',
          subTitle: 'Mga slot, Andar Bahar, Blackjack, Roulette at marami pang iba!',
          textWight: window.outerWidth <= 426 ? '230px' : '400px',
          text: 'Maglaro at manalo sa pinakamagandang casino!',
          textBtn: 'Pumunta sa website',
          src: 'https://cropped.xyz/2gdpz'
        },
        'tr': {
          title: 'sizi bekliyor!',
          subTitle: 'Slotlar, Andar Bahar, Blackjack, Rulet ve çok daha fazlası!',
          textWight: window.outerWidth <= 426 ? '165px' : '290px',
          text: 'En iyi casinoda oynayın ve kazanın!',
          textBtn: 'Web sitesine git',
          src: 'https://cropped.xyz/nlid0'
        },
      },
      selectLang: 'en',
      wightWin: window.outerWidth
    }
  }
}
</script>

<style lang="scss"></style>
